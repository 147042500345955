import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { format } from 'date-fns'

import title1Styles from '../styles/title1'
import labelSmallStyles from '../styles/labelSmall'
import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'

const Wrapper = styled(Link)`
  text-align: center;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
  text-decoration: none;
`

const Title = styled.h1`
  ${title1Styles}
  margin: 0;
  padding: 0;
  font-weight: normal;

  @media (max-width: 640px) {
    font-size: 2.5rem;
  }

  @media (max-width: 540px) {
    font-size: 2rem;
  }

  @media (max-width: 420px) {
    font-size: 1.75rem;
  }
`

const Datetime = styled.div`
  ${labelSmallStyles}
  margin-bottom: 0.25rem;
`

const NewsPreview = ({ news, ...props }) => {
  const i18n = useContext(I18nContext)

  return (
    <Wrapper to={`/${i18n.currentLocale}/news/${news.slug}`} {...props}>
      <Datetime>{format(new Date(news.date), 'dd.MM.yyyy')}</Datetime>
      <Title>{news.title}</Title>
    </Wrapper>
  )
}

export default NewsPreview
