import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Hero from '../components/Hero'
import TeamHome from '../components/TeamHome'
import NewsBox from '../components/NewsBox'
import Spacer from '../components/Spacer'
import Awards from '../components/Awards'

const HomePage = ({ data }) => {
  const team = data.allContentfulTeam.edges.map(edge => edge.node)
  const news = data.allContentfulNews.edges.map(edge => edge.node)
  const competences = data.allContentfulCompetence.edges.map(edge => edge.node)

  return (
    <Layout showLogo>
      <SEO />
      <Hero competences={competences} />
      <TeamHome team={team} />
      <NewsBox news={news} />
      <Spacer size="small" />
      <Awards />
      <Spacer />
    </Layout>
  )
}

export default HomePage

export const i18n = {
  en: '/',
  de: '/',
}

export const query = graphql`
  query($i18nCurrentLocale: String!) {
    allContentfulTeam(filter: { node_locale: { eq: $i18nCurrentLocale } }) {
      edges {
        node {
          id
          name
          slug
          status {
            title
          }
          photo {
            fixed(quality: 100, width: 456) {
              src
            }
            file {
              url
            }
          }
        }
      }
    }
    allContentfulNews(
      limit: 7
      filter: { node_locale: { eq: $i18nCurrentLocale } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          title
          slug
          date
        }
      }
    }
    allContentfulCompetence(
      sort: { fields: title }
      filter: { node_locale: { eq: $i18nCurrentLocale } }
    ) {
      edges {
        node {
          id
          title
          slug
          content {
            json
          }
        }
      }
    }
  }
`
