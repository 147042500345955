import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { shuffle } from 'lodash'
import { FormattedMessage } from 'react-intl'

import Content from './Content'
import Title from './Title'
import Button from './Button'
import Action from './Action'
import Profile from './Team/Profile'
import isPartner from '../utils/isPartner'
import I18nContext from '../../plugins/gatsby-plugin-i18n/utils/I18nContext'

const Wrapper = styled.section`
  padding: 3rem 0 4rem 0;
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
`

const Item = styled.div`
  width: 33.333333%;
  padding: 0 0 2rem 20px;

  @media (max-width: 680px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

const TeamHome = ({ team }) => {
  const i18n = useContext(I18nContext)
  const [partners, setPartners] = useState()

  const filteredTeam = team.filter(member => {
    return isPartner(member.status.title)
  })

  useEffect(() => {
    const shuffledTeam = shuffle(filteredTeam)
    setPartners(shuffledTeam)
  }, [])

  const tempTeam = partners || filteredTeam

  const finalTeam = tempTeam.slice(0, 6)

  return (
    <Wrapper>
      <Content>
        <Title top="Team">
          <FormattedMessage id="team.headline" />
        </Title>
        <Items>
          {finalTeam.map(member => (
            <Item key={member.id}>
              <Profile member={member} />
            </Item>
          ))}
        </Items>
        <Action>
          <Button to={`/${i18n.currentLocale}/team`}>
            <FormattedMessage id="team.overview" />
          </Button>
        </Action>
      </Content>
    </Wrapper>
  )
}

export default TeamHome
