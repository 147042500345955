import React, { useRef } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Content from './Content'
import Title from './Title'
import NewsPreview from './NewsPreview'
import Box from './Box'
import Slider from './Slider'
import labelStyle from '../styles/label'

const Wrapper = styled.section``

const StyledBox = styled(Box)`
  padding: 3rem 0 4rem 0;
`

const NextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Next = styled.button`
  ${labelStyle};
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  padding: 1rem;
  margin-bottom: -1rem;
  background: transparent;
`

const StyledNewsPreview = styled(NewsPreview)`
  margin-bottom: 6rem;
  margin-top: 3rem;
`

const NewsBox = ({ news }) => {
  const sliderRef = useRef()

  const onNext = () => {
    sliderRef.current.slickNext()
  }

  const slides = news.map(item => <StyledNewsPreview news={item} />)

  return (
    <Wrapper>
      <Content>
        <StyledBox>
          <Title top="News" />
          <Slider ref={sliderRef} slides={slides} />
          <NextWrapper>
            <Next onClick={onNext}>
              <FormattedMessage id="next" />
            </Next>
          </NextWrapper>
        </StyledBox>
      </Content>
    </Wrapper>
  )
}

export default NewsBox
