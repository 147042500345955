import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'

import Content from './Content'
import CompetencesNav from './CompetencesNav'
// TODO: Use gatsby-image
import officeImg from '../images/gasser_partner_office.jpg'
// import locationButtonDE from '../images/button_de.png'
// import locationButtonEN from '../images/button_en.png'

const Wrapper = styled.section`
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadein 2s;
`

const StyledContent = styled(Content)`
  position: relative;
  z-index: 1;
`

const Sidebar = styled.div`
  width: 560px;
  min-height: 100vh;
  padding: 3rem 2.5rem 3rem 3rem;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 8rem; /* make room for logo */
  padding-bottom: 4rem;

  /* Move sidebar to the left to align inner content with page container. */
  left: calc(-3rem - 1px);

  @media (max-width: 1140px) {
    width: 500px;
  }

  @media (max-width: 620px) {
    width: 100%;
    left: 0;
    padding-left: 0px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: 0;
    bottom: -10px;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    filter: blur(7px);
    bottom: -300px;

    @media (max-width: 1170px) {
      left: -20px;
    }

    @media (max-width: 620px) {
      left: -40px;
      right: -40px;
      bottom: -40px;
      top: -40px;
      bottom: -300px;
    }
  }
`

const Main = styled(animated.div)`
  position: relative;
  z-index: 2;
`

// const LocationButtonImage = styled.img`
//   width: 250px;
//   display: block;
//   position: relative;
//   margin: 0 0 30px auto;
//   margin-top: -8vh;

//   @media (min-width: 720px) {
//     margin: 0;
//     position: absolute;
//     bottom: 200px;
//     right: 24px;
//     width: 285px;
//     height: auto;
//     /*
//     position: relative;
//     margin: -70px 0 0 auto;

//     */
//   }
// `

const Hero = ({ competences }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })

  const props = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0%)' : 'translateY(30%)',
  })

  return (
    <Wrapper>
      <StyledContent>
        <Sidebar ref={ref}>
          <Main style={props}>
            <CompetencesNav competences={competences} />
          </Main>
        </Sidebar>
        {/*
        <LocationButtonImage
          src={intl.locale === 'de' ? locationButtonDE : locationButtonEN}
          alt=""
        />
        */}
      </StyledContent>
      <Image src={officeImg} alt="" />
    </Wrapper>
  )
}

export default Hero
