import React from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Content from './Content'
import Title from './Title'
import bodyMediumStyles from '../styles/bodyMedium'
import badge5 from '../images/badge_5.jpg'
import badge6 from '../images/badge_6.png'
import partner1Img from '../images/FIRE_Positive_RGB_Short.svg'
import partner2Img from '../images/PrivateClient_Positive_RGB_Short.svg'
import award1 from '../images/awards_2024/Chambers_Global_24.svg'
import award2 from '../images/awards_2024/Chambers_Europe_24.svg'
import award3 from '../images/awards_2024/Chambers_HighNetWorth_24.svg'
import award4 from '../images/awards_2024/Chambers_FinTech_24.svg'
import award5 from '../images/awards_2024/LEGAL500_TopTierFirm_24.svg'

const Wrapper = styled.div``

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -20px;

  ${props =>
    props.partners &&
    css`
      margin-top: -1.5rem;
    `}
`

const Item = styled.div`
  flex-basis: 16.666666%;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
`

const Award = styled.a`
  text-align: center;
  min-width: 120px;
  display: block;
  text-decoration: none;
`

const Media = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
`

const Img = styled.img`
  display: block;
  margin: 0;
  max-width: 100%;
  height: auto;
`

const Name = styled.h2`
  ${bodyMediumStyles}
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: normal;
  display: none;
`

const Awards = () => {
  return (
    <Wrapper>
      <Content>
        <Title size="small" line={false}>
          Awards
        </Title>
        <Items>
          <Item>
            <Award
              href="https://chambers.com/law-firm/gasser-partner-global-2:22909012"
              target="_blank"
              rel="nofollow noopener"
            >
              <Media>
                <Img src={award1} alt="" width="130" />
              </Media>
              <Name>
                Top Ranked –<br />
                Chambers Global 2024
              </Name>
            </Award>
          </Item>
          <Item>
            <Award
              href="https://chambers.com/law-firm/gasser-partner-attorneys-at-law-europe-7:22909012"
              target="_blank"
              rel="nofollow noopener"
            >
              <Media>
                <Img src={award2} alt="" width="130" />
              </Media>
              <Name>
                Top Ranked –<br />
                Chambers Europe 2024
              </Name>
            </Award>
          </Item>
          <Item>
            <Award
              href="https://chambers.com/law-firm/gasser-partner-attorneys-at-law-high-net-worth-21:22909012"
              target="_blank"
              rel="nofollow noopener"
            >
              <Media>
                <Img src={award3} alt="" width="130" />
              </Media>
              <Name>
                Top Ranked –<br />
                Chambers High Net Worth 2024
              </Name>
            </Award>
          </Item>
          <Item>
            <Award
              href="https://chambers.com/law-firm/gasser-partner-attorneys-at-law-fintech-49:22909012"
              target="_blank"
              rel="nofollow noopener"
            >
              <Media>
                <Img src={award4} alt="" width="130" />
              </Media>
              <Name>
                Ranked –<br />
                Chambers FinTech 2024
              </Name>
            </Award>
          </Item>
          <Item>
            <Award
              href="https://www.legal500.com/firms/10269-gasser-partner-attorneys-at-law/c-liechtenstein/about"
              target="_blank"
              rel="nofollow noopener"
            >
              <Media>
                <Img src={award5} alt="" width="78" />
              </Media>
              <Name>
                TOP TIER –<br />
                Legal 500 EMEA 2024
              </Name>
            </Award>
          </Item>
        </Items>
        <br />
        <br />
        <Title size="small" line={false}>
          <FormattedMessage id="partnerships.title" />
        </Title>
        <Items partners>
          <Item>
            <Award as="div">
              <Media>
                <Img src={partner1Img} alt="" width="120" />
              </Media>
            </Award>
          </Item>
          <Item>
            <Award as="div">
              <Media>
                <Img
                  src={partner2Img}
                  alt=""
                  width="220"
                  style={{ minWidth: '200px' }}
                />
              </Media>
            </Award>
          </Item>
          <Item>
            <Award
              href="https://www.uibk.ac.at/foerderkreis1669/"
              target="_blank"
              rel="nofollow noopener"
            >
              <Media>
                <Img src={badge5} alt="" width="170" />
              </Media>
              <Name>
                Gründer des Förderkreises „1669 – Wissenschaft Gesellschaft“
                Universität Innsbruck
              </Name>
            </Award>
          </Item>
          <Item>
            <Award>
              <Media>
                <Img src={badge6} alt="" width="168" />
              </Media>
              <Name>
                Gasser Partner wurde von der Leopold-Franzens-Universität
                Innsbruck für das erfolgreiche Engagement in der universitären
                Praxisausbildung mit An- erkennung gewürdigt.
              </Name>
            </Award>
          </Item>
        </Items>
      </Content>
    </Wrapper>
  )
}

export default Awards
